import styled from 'styled-components/macro';


export const FavIcon = styled.div`
  width: 50px;
  height: 50px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
  background-image: url(${require('static/fav-icon.png')});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  background-size: 50%;
`;

export const FavContainer = styled.div`
  width: 50px;
  height: 50px;
  position: relative;
`;
