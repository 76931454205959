import { useEffect, useState } from 'react';

import Loader from 'components/Loader';
import Filter from 'components/Filter';
import Products from 'components/Products';
import Cart from 'components/Cart';
import Profile from 'components/Profile';
import Fav from 'components/Fav';
import Logo from 'components/Logo';
import Search from 'components/Search';
import Wine from 'components/Filter/Wine';
import Grape from 'components/Filter/Grape';
import Country from 'components/Filter/Country';
import Region from 'components/Filter/Region';

import { useProducts } from 'contexts/products-context';

import * as S from './style';

function App() {
  const { isFetching, products, fetchProducts } = useProducts();

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);


  const [ showDropDown , setShowDropDown] = useState(false);

  const showdropdowns = () => {
    setShowDropDown(!showDropDown);
  }

  return (
    <S.Container>
      <S.ThreeColumnGrid>
        <S.CartSideLeft><Search/></S.CartSideLeft>
        <S.MiddleSide><Logo/></S.MiddleSide>
        <S.CartSide>
          <S.ThreeColumnGridInner>
            <S.CartSide>
              <Fav />
            </S.CartSide>
            <S.CartSide>
              <Profile />
            </S.CartSide>
            <S.CartSide>
              <Cart />
            </S.CartSide>
          </S.ThreeColumnGridInner>
        </S.CartSide>
      </S.ThreeColumnGrid>
      <S.ThreeColumnGridNarrow>
        <S.CartSideLeft></S.CartSideLeft>
        <S.MiddleSide>
        <S.FourColumnGrid>
            <S.CartSide>
              <Wine  showDropDown={showDropDown} handleOnDropDown={showdropdowns}/>
            </S.CartSide>
            <S.CartSide>
              <Grape  showDropDown={showDropDown} handleOnDropDown={showdropdowns}/>
            </S.CartSide>
            <S.CartSide>
              <Country showDropDown={showDropDown} handleOnDropDown={showdropdowns}/>
            </S.CartSide>
            <S.CartSide>
              <Region  showDropDown={showDropDown} handleOnDropDown={showdropdowns}/>
            </S.CartSide>
          </S.FourColumnGrid>
        </S.MiddleSide>
        <S.CartSide></S.CartSide>
      </S.ThreeColumnGridNarrow>
    </S.Container>
  );
}

export default App;
