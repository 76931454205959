import formatPrice from 'utils/formatPrice';
import * as S from './style';
import { useProducts } from 'contexts/products-context';
import { useNavigate } from "react-router-dom";
import { useState } from 'react';

interface IProps {
  showDropDown: boolean;
  handleOnDropDown(): void;
}

const Logo = ({ showDropDown, handleOnDropDown }: IProps) => {

  const { filters, filterProducts, products } = useProducts();
  const navigate = useNavigate();

  let availableCountry:string[] = [];
  const selectedCheckboxes = new Set(filters.country);

  products.forEach(p => {
    if (availableCountry.indexOf(p.country) === -1) {
      availableCountry.push(p.country);
    }
  });

  const createCheckbox = (label: string) => (
    <S.CheckboxLarge label={label} handleOnChange={toggleCheckbox} key={label} />
  );

  const createCheckboxes = () => availableCountry.map(createCheckbox);

  const toggleCheckbox = (label: string) => {
    window.location.href = "/?country=" + label;
  }

  return (
    <S.Container>
      <S.Icon onClick={handleOnDropDown}/>
      {showDropDown &&
        <S.DropDownContainer>
          {createCheckboxes()}
        </S.DropDownContainer>
      }

    </S.Container>

  );
};

export default Logo;
