import { useState } from 'react';

interface IProps {
  value: number;
  handleMinus(): void;
  handlePlus(): void;
}

const NumberInput = ({ value, handlePlus, handleMinus }: IProps) => {

  return (
    <div className="numberwrapper">
        <button className="plusminus" onClick={handleMinus}>-</button>
        <input type="number" className="numberCart" value={value} onChange={()=>{}}/>
        <button className="plusminus" onClick={handlePlus}>+</button>
    </div>
  );
};

export default NumberInput;
