import { useProducts } from 'contexts/products-context';
import { useEffect, useState } from 'react';

import * as S from './style';

export const availableSizes = ['XS', 'S', 'M', 'ML', 'L', 'XL', 'XXL'];

export const availableTypes = ['Sparkling', 'White', 'Rose', 'Red', 'Fine Wine', 'Organic', 'Vegan/Vegetarian'];



const Filter = () => {
  const { filters, filterProducts, products } = useProducts();
  const [princeRangeValue, setprinceRangeValue] = useState(10000);
  const selectedCheckboxes = new Set(filters.size);
  const selectedTypeCheckboxes = new Set(filters.type);
  const selectedGrapeCheckboxes = new Set(filters.grape);
  const selectedCountryCheckboxes = new Set(filters.country);
  const selectedRegionCheckboxes = new Set(filters.region);


  const queryParams = new URLSearchParams(window.location.search);
  const country = queryParams.get('country');
  const region = queryParams.get('region');
  const wine = queryParams.get('wine');
  const grape = queryParams.get('grape');

  let availableGrapeVariety:string[] = [];
  let availableCountry:string[] = [];
  let availableRegion:string[] = [];

  products.forEach(p => {
    if (availableGrapeVariety.indexOf(p.grape_variety) === -1) {
      availableGrapeVariety.push(p.grape_variety);
    }
    if (availableCountry.indexOf(p.country) === -1) {
      availableCountry.push(p.country);
    }
    if (availableRegion.indexOf(p.region) === -1) {
      availableRegion.push(p.region);
    }
  });

  useEffect(() => {

    setTimeout(() => {
      if(country != null) {
        if (!selectedCountryCheckboxes.has(country)) {
          toggleCountryCheckbox(country);
        }
      }
      if(grape != null) {
        if (!selectedGrapeCheckboxes.has(grape)) {
          toggleGrapeCheckbox(grape);
        }
      }
      if(wine != null) {
        if (!selectedCheckboxes.has(wine)) {
          toggleCheckbox(wine);
        }
      }
      if(region != null) {
        if (!selectedRegionCheckboxes.has(region)) {
          toggleRegionCheckbox(region);
        }
      }
    }, 1000)



  });



  availableGrapeVariety.sort();
  availableCountry.sort();
  availableRegion.sort();

  const dofilter = () => {
    const filters = {
      'size' :Array.from(selectedCheckboxes) as [],
      'type' :Array.from(selectedTypeCheckboxes) as [],
      'grape' :Array.from(selectedGrapeCheckboxes) as [],
      'country' :Array.from(selectedCountryCheckboxes) as [],
      'region' :Array.from(selectedRegionCheckboxes) as [],
      'price' :Number.parseInt(princeRangeValue.toString())
    };
    filterProducts(filters);
  };


  let pricetimer:NodeJS.Timeout;

  const setPriceRange =  (evt: any) => {

    if (pricetimer) {
      clearTimeout(pricetimer);
    }



    pricetimer = setTimeout(() => {
      let value = evt.target.value;
      setprinceRangeValue(value);
      dofilter();
    }, 500);
  }

  const toggleCountryCheckbox = (label: string) => {
    if (selectedCountryCheckboxes.has(label)) {
      selectedCountryCheckboxes.delete(label);
    } else {
      selectedCountryCheckboxes.add(label);
    }
    dofilter();
  }
  const toggleRegionCheckbox = (label: string) => {
    if (selectedRegionCheckboxes.has(label)) {
      selectedRegionCheckboxes.delete(label);
    } else {
      selectedRegionCheckboxes.add(label);
    }
    dofilter();
  }
  const toggleGrapeCheckbox = (label: string) => {
    if (selectedGrapeCheckboxes.has(label)) {
      selectedGrapeCheckboxes.delete(label);
    } else {
      selectedGrapeCheckboxes.add(label);
    }
    dofilter();
  }

  const toggleTypeCheckbox = (label: string) => {
    if (selectedTypeCheckboxes.has(label)) {
      selectedTypeCheckboxes.delete(label);
    } else {
      selectedTypeCheckboxes.add(label);
    }
    dofilter();
  }

  const toggleCheckbox = (label: string) => {
    if (selectedCheckboxes.has(label)) {
      selectedCheckboxes.delete(label);
    } else {
      selectedCheckboxes.add(label);
    }
    dofilter();
  }

  const createCheckLargebox = (label: string) => (
    <S.CheckboxLarge label={label} handleOnChange={toggleTypeCheckbox} key={label} />
  );

  const createCheckGrapebox = (label: string) => (
    <S.CheckboxLarge label={label} handleOnChange={toggleGrapeCheckbox} key={label} />
  );

  const createCheckCountrybox = (label: string) => (
    <S.CheckboxLarge label={label} handleOnChange={toggleCountryCheckbox} key={label} />
  );

  const createCheckRegionbox = (label: string) => (
    <S.CheckboxLarge label={label} handleOnChange={toggleRegionCheckbox} key={label} />
  );

  const createTypeCheckboxes = () => availableTypes.map(createCheckLargebox);
  const createGrapeCheckboxes = () => availableGrapeVariety.map(createCheckGrapebox);
  const createCountryCheckboxes = () => availableCountry.map(createCheckCountrybox);
  const createGrapeRegionboxes = () => availableRegion.map(createCheckRegionbox);

  return (
    <S.Container>
      <S.InnerContainer>
      <S.MainTitle>FILTERED <i>by</i></S.MainTitle>
      <S.Title>Price</S.Title>
      <input type="range" defaultValue={princeRangeValue} max="10000" step="10" onChange={setPriceRange} className="priceSlider"/>&nbsp;&pound;{princeRangeValue}
      <S.Title>Types</S.Title>
      {createTypeCheckboxes()}
      <S.Title>Grape</S.Title>
      {createGrapeCheckboxes()}
      <S.Title>Country</S.Title>
      {createCountryCheckboxes()}
      <S.Title>Region</S.Title>
      {createGrapeRegionboxes()}
      </S.InnerContainer>
    </S.Container>
  );
};

export default Filter;
