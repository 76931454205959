import { KeyboardEvent, useState } from 'react';
import { useNavigate } from "react-router-dom";

import formatPrice from 'utils/formatPrice';
import { IWine } from 'models';

import { useCart } from 'contexts/cart-context';

import NumberInput from 'commons/NumberInput';

import * as S from './style';

interface IProps {
  product: IWine;
}

const Cart = ({ product }: IProps) => {

  const navigate = useNavigate();
  const { openCart, addProduct } = useCart();
  const isProduction = process.env.NODE_ENV === 'production';
  const {
    sku,
    retail_price,
    currencyId,
    currencyFormat,
    images,
    winery,
  } = product;

  const formattedPrice = formatPrice(retail_price, currencyId);
  const [ addQuantity , setAddQuanity] = useState(1);
  let imageurl;
  let wineryName = "";

  if (images.length > 0) {
    if (isProduction) {
        imageurl = "'" + process.env.REACT_APP_BASEURL_PRODUCTION! + process.env.REACT_APP_WINE_IMAGE_PATH! + images[0].name + "'";
    } else {
      imageurl = "'" + process.env.REACT_APP_BASEURL_DEVELOPMENT! + process.env.REACT_APP_WINE_IMAGE_PATH! + images[0].name + "'";
    }

  } else {
    imageurl = process.env.REACT_APP_WINE_DUMMY_IMAGE
  }


  if (winery.name) {
    wineryName = winery.name;
  }

  const handleAddProduct = () => {
    addProduct({ ...product, quantity: addQuantity });
    openCart();
  };


  const handleMinus = () => {
    let value = addQuantity
    value--;
    if ( value < 1) { value= 1; }
    setAddQuanity(value);
  }
  const handlePlus = () => {
    let value = addQuantity
    value++;
    setAddQuanity(value);
  }




  const handleShowProduct = () => {
    navigate("/detail/" + product.sku);
  };


  return (
    <S.Container sku={sku} tabIndex={1}>
      <S.InnerContainer onClick={handleShowProduct}>

        <S.BuyContainer>
          <S.TwoColumnGrid>
            <S.PriceSide>
              <S.Price>
                <S.Val>
                  <b>{currencyFormat}</b>
                  <b>{formattedPrice.substring(0, formattedPrice.length - 3)}</b>
                  <span>{formattedPrice.substring(formattedPrice.length - 3)}</span>
                </S.Val>
                <S.DeliverOptions>Price per bottle</S.DeliverOptions>
              </S.Price>
             </S.PriceSide>
             <S.PriceSide><S.ShareIcon /></S.PriceSide>
             <S.PriceSide><S.FavIcon /></S.PriceSide>
          </S.TwoColumnGrid>
          <br/><br/>
          <NumberInput value={addQuantity} handleMinus={handleMinus} handlePlus={handlePlus} />
          <S.BuyButton onClick={handleAddProduct} tabIndex={-1}>
            ADD TO BAG
          </S.BuyButton>
          <br/><br/><br/>
          <S.DetailContainer>
            <S.DeliverOptions><S.DeliveryIcon />Check our delivery options</S.DeliverOptions>
          </S.DetailContainer>
        </S.BuyContainer>

    </S.InnerContainer>
    </S.Container>
  );
};

export default Cart;
