import styled from 'styled-components/macro';

export const BuyButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: #fff;
  padding: 15px 0;
  margin-top: 10px;
  cursor: pointer;
  border: 0;

  width: 85%;
  height: 52px;
  background: #001950 0% 0% no-repeat padding-box;
  border-radius: 26px;
  opacity: 1;

  font: normal normal bold 16px/15px 'Montserrat';

  transition: background-color 0.2s;

  &:focus-visible {
    outline: 3px solid ${({ theme }) => theme.colors.secondary};
  }
`;

export const QuantityInput = styled.input`
  text-align: center;
  border: 1px solid #6C757D;

`;

interface IImage {
  alt: string;
}
export const Image = styled.div<IImage>``;

interface IContainer {
  sku: number | string;
}
export const Container = styled.div<IContainer>`
  position: relative;
  text-align: center;
  box-sizing: border-box;
  padding: 10px;
  margin-bottom: 30px;
  margin-top: 50px;
  cursor: default;
  outline: none;


  &:focus-visible {
    outline: 3px solid ${({ theme }) => theme.colors.secondary};
  }

  ${Image} {
    width: 100%;
    height: 350px !important;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-top:-75px;

    ::before {
      content: '';
      display: block;
      position: absolute;
      background: transparent;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
        breakpoints.tablet}) {
      height: 320px;
    }
  }

  &:hover {
    ${Image} {
      background-image: ${({ sku }) =>
        `url()`};
    }

    ${BuyButton} {
      background-color: ${({ theme }) => theme.colors.secondary};
    }
  }
`;
export const InnerContainer = styled.div`
  position: relative;
  box-shadow: 0px 3px 6px #00000015;
  border: 1px solid #ECEBEA;
  border-radius: 19px;
  opacity: 1;
  width:100%;
  height:100%
`;

export const ImageContainer = styled.div`
  position: relative;
  width:35%;
  display:block;
  float:left;
`;

export const BuyContainer = styled.div`
position: relative;
width:100%;
height:350px;
float:left;
display:block;
`;


export const DetailContainer = styled.div`
  position: flex;
  width:100%;
  height:150px;
  float:left;
  display:block;
  align-content:bottom
`;

export const Stopper = styled.div`
  position: absolute;
  color: #ececec;
  top: 10px;
  right: 10px;
  padding: 5px;
  font-size: 0.6em;
  background-color: ${({ theme }) => theme.colors.primary};
  cursor: default;
  z-index: 1;
`;

export const Title = styled.p`
  position: relative;
  padding: 0 20px;
  margin:0;
  height: 64px;
  font-size:24px;
  font-weight:700;
  font: normal normal bold 24px/28px 'Montserrat';
  text-align:left;
`;
export const TwoColumnGrid = styled.main`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1366px;
  margin: 10px auto auto;

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    grid-template-columns: 2fr 1fr 1fr;
  }
`;

export const PriceSide = styled.div`
  display: grid;
  justify-content: center;
  padding: 15px;
  box-sizing: border-box;

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    align-content: baseline;
  }
`;

export const Winery = styled.p`
  position: relative;
  padding: 0 20px;
  margin:0;
  height: 21px;
  font-size:24px;
  font-weight:700;
  font-family: 'Crimson Text', serif;
  font: italic normal normal 21px/15px 'Crimson Text';
  text-align:left;
`;
export const DeliveryIcon = styled.span`
  width: 60px;
  height: 60px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
  background-image: url(${require('static/delivery-icon.png')});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  background-size: 50%;
`;
export const FavIcon = styled.div`
  width: 30px;
  height: 30px;
  position: relative;
  float:left;
  margin-right: 5px;
  background-image: url(${require('static/fav-icon.png')});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;
export const ShareIcon = styled.div`
  width: 30px;
  height: 30px;
  position: relative;
  float:right;
  margin-right: 5px;
  background-image: url(${require('static/share-icon.png')});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;
export const DeliverOptions = styled.p`
  position: relative;
  padding: 0 20px;
  margin:0;
  height: 15px;
  font: normal normal normal 14px/15px 'Montserrat';
  text-align:left;
  color: #808080;
`;

export const Price = styled.div`
  height: 60px;
  .val {
    b {

      font-size: 1.5em;
      margin-left: 5px;
    }
  }
`;

export const Val = styled.p`
  font-family: 'Crimson Text';
  font-size: 25px;
  margin: 0;
  b {
    font-size: 1.5em;
    margin-left: 5px;
  }
`;

export const Installment = styled.p`
  margin: 0;
  color: #9c9b9b;
`;
