import { StrictMode } from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

/* Theme */
import { ThemeProvider } from 'commons/style/styled-components';
import { theme } from 'commons/style/theme';
import GlobalStyle from 'commons/style/global-style';

/* Context Providers */
import { ProductsProvider } from 'contexts/products-context';
import { CartProvider } from 'contexts/cart-context';

import App from 'components/App';
import Detail from 'components/Detail';

const root = document.getElementById('root')!;
const container = ReactDOMClient.createRoot(root);

container.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={
              <ProductsProvider>
                <CartProvider>
                  <App />
                </CartProvider>
              </ProductsProvider>}>
            </Route>
            <Route path="/detail/:sku" element={
              <ProductsProvider>
                <CartProvider>
                  <Detail />
                </CartProvider>
              </ProductsProvider>}>
            </Route>
          </Routes>
        </BrowserRouter>
    </ThemeProvider>
  </StrictMode>
);
