import { useEffect, useState } from 'react';

import Loader from 'components/Loader';
import Filter from 'components/Filter';
import Products from 'components/Products';
import Header from 'components/Header/Header';

import { useProducts } from 'contexts/products-context';

import * as S from './style';

function App() {
  const { isFetching, products, fetchProducts } = useProducts();

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);


  const [ showDropDown , setShowDropDown] = useState(false);

  const showdropdowns = () => {
    setShowDropDown(!showDropDown);
  }

  return (
    <S.Container>
      {isFetching && <Loader />}
      <Header/>
      <S.Banner/>
      <S.TwoColumnGrid>
        <S.Side>
        </S.Side>
        <S.Main><S.MainTitle><i>our</i> FINE<br/>WINES</S.MainTitle></S.Main>
      </S.TwoColumnGrid>
      <S.TwoColumnGrid>
        <S.Side>
          <Filter />
        </S.Side>
        <S.Main>
          <S.MainHeader>
            <p>{products?.length} Wine(s) found</p>
          </S.MainHeader>
          <Products products={products} />
        </S.Main>
      </S.TwoColumnGrid>

    </S.Container>
  );
}

export default App;
