import { createGlobalStyle } from './styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  input[type="number"] {
    -webkit-appearance: textfield !important;
    -moz-appearance: textfield !important;
    appearance: textfield !important;
    }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }

  .numberwrapper {
    border: 1px #dcd3d3 solid;
    width: 85%;
    height: 48px;
    display: flex;
    border-radius: 24px;
    margin: 0 auto auto;
  }

  .plusminus {
    height: 100%;
    width: 30%;
    background: transparent;
    border: none;
    font-size: 25px;
    color: #8D8D8D;
    text-align:center;
  }

  .numberCart {
    height: 100%;
    width: 39%;
    border: none;
    font-size: 30px;
    text-align: center;
    background: transparent;
    font-family: 'Crimson Text', serif;
  }
  .numberCart:focus {
    outline: none !important;
    border:0px solid transparent;
  }


  .priceSlider {
    color:black;
    width:65%;
    accent-color: #000000;
  }

`;

export default GlobalStyle;
