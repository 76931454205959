import styled from 'styled-components/macro';


export const SearchIcon = styled.div`
  width: 50px;
  height: 50px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  background-image: url(${require('static/search-icon.png')});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  background-size: 50%;
`;

export const SearchContainer = styled.div`
width: 50px;
height: 50px;
position: relative;
`;
