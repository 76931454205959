import styled from 'styled-components/macro';

import CB from 'commons/Checkbox';

export const Container = styled.div`
  position: relative;
  box-shadow: 0px 3px 6px #00000015;
  border: 1px solid #ECEBEA;
  border-radius: 19px;
  opacity: 1;
  width: 337px;
  height:100%
`;
export const InnerContainer = styled.div`
  position: relative;
  width: 100%;
  height:100%;
  padding:30px;
`;
export const Checkbox = styled(CB)`
  display: inline-block;
  margin-bottom: 10px;

  /* Customize the label (the container) */
  label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 35px;
    height: 35px;
    font-size: 0.8em;
    margin-bottom: 8px;
    margin-right: 8px;
    border-radius: 50%;
    line-height: 35px;
    text-align: center;

    /* On mouse-over, add a border with the primary color */
    &:hover input ~ .checkmark {
      border: 1px solid ${({ theme }) => theme.colors.primary};
    }

    input:focus-visible ~ .checkmark {
      box-sizing: border-box;
      line-height: 30px;
      border: 3px solid ${({ theme }) => theme.colors.secondary};
    }

    /* When the checkbox is checked, add the primary color to background */
    & input:checked ~ .checkmark {
      background-color: ${({ theme }) => theme.colors.primary};
      color: #ececec;
    }

    /* Show the checkmark when checked */
    & input:checked ~ .checkmark:after {
      display: block;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      width: 35px;
      height: 35px;
      font-size: 0.8em;
      border-radius: 50%;
      box-sizing: border-box;
      line-height: 35px;
      text-align: center;
      color: ${({ theme }) => theme.colors.primary};
      background-color: #ececec;

      border: 1px solid transparent;
    }
  }
`;

export const CheckboxLarge = styled(CB)`
  display: block;
  margin-bottom: 10px;

  /* Customize the label (the container) */
  label {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 80%;
    height: 25px;
    margin-bottom: 8px;
    margin-right: 8px;
    text-align: center;
    font: normal normal normal 16px/58px  'Montserrat';

    /* On mouse-over, add a border with the primary color */
    &:hover input ~ .checkmark {
      border: 1px solid ${({ theme }) => theme.colors.primary};
    }

    input:focus-visible ~ .checkmark {
      box-sizing: border-box;
      line-height: 30px;
      border: 3px solid ${({ theme }) => theme.colors.secondary};
    }

    /* When the checkbox is checked, add the primary color to background */
    & input:checked ~ .checkmark {
      background-color: ${({ theme }) => theme.colors.primary};
      color: #ececec;
    }

    /* Show the checkmark when checked */
    & input:checked ~ .checkmark:after {
      display: block;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      width: 80%;
      height: 30px;
      font-size: 0.8em;
      border-radius: 5px;
      box-sizing: border-box;
      line-height: 35px;
      text-align: center;
      padding-left:5px;

      color: ${({ theme }) => theme.colors.primary};
      text-align:left;
      background-color: transparent;

      border: 1px solid transparent;
    }
  }
`;

export const Title = styled.h4`
  margin-top: 2px;
  margin-bottom: 20px;
  font: normal normal 25px/58px 'Montserrat';
  font-weight:600;
`;

export const MainTitle = styled.h3`
  margin-top: 2px;
  padding-bottom: 30px;
  font: normal normal normal 44px/58px 'Crimson Text';
  border-bottom: 2px solid #E2E2E2;
  width:80%;
  opacity: 1;
`;
