
import { IWine } from 'models';

import * as S from './style';

interface IProps {
  product: IWine;
}

const Product = ({ product }: IProps) => {

  const isProduction = process.env.NODE_ENV === 'production';
  const {
    sku,
    name,
    color,
    size,
    grape_variety,
    vintage,
    images,
    winery,
    country,
    description
  } = product;

  let imageurl;
  let wineryName = "";

  if (images.length > 0) {
    if (isProduction) {
        imageurl = "'" + process.env.REACT_APP_BASEURL_PRODUCTION! + process.env.REACT_APP_WINE_IMAGE_PATH! + images[0].name + "'";
    } else {
      imageurl = "'" + process.env.REACT_APP_BASEURL_DEVELOPMENT! + process.env.REACT_APP_WINE_IMAGE_PATH! + images[0].name + "'";
    }

  } else {
    imageurl = process.env.REACT_APP_WINE_DUMMY_IMAGE
  }

  if (winery.name) {
    wineryName = winery.name;
  }

  return (
    <S.Container sku={sku} tabIndex={1}>
      <S.InnerContainer>

        <S.TwoColumnGrid>

        <S.ImageSide>

          <S.ImageContainer>
            <S.Image alt={name} style={{background: `url(${imageurl})`,   backgroundPosition: 'center',  backgroundSize: 'cover',  backgroundRepeat: 'no-repeat'}}/>
          </S.ImageContainer>

        </S.ImageSide>

        <S.DetailedSide>

          <S.DetailContainer>
            <S.Winery>{wineryName} {}  </S.Winery>
            <S.Title>{name} {vintage}</S.Title>

            <S.InfoText>{grape_variety}</S.InfoText>
            <S.InfoText>{color}</S.InfoText>
            <S.InfoText>{size}ml</S.InfoText>
            <S.InfoText>{country}</S.InfoText>
            <S.Description>
              <div dangerouslySetInnerHTML={{__html: description}} />
            </S.Description>

          </S.DetailContainer>

        </S.DetailedSide>

        </S.TwoColumnGrid>
    </S.InnerContainer>
    </S.Container>
  );
};

export default Product;
