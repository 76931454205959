import { KeyboardEvent } from 'react';
import { useNavigate } from "react-router-dom";

import formatPrice from 'utils/formatPrice';
import { IWine } from 'models';

import { useCart } from 'contexts/cart-context';

import * as S from './style';
import { log } from 'console';

interface IProps {
  product: IWine;
}

const Product = ({ product }: IProps) => {

  const navigate = useNavigate();
  const { openCart, addProduct } = useCart();
  const isProduction = process.env.NODE_ENV === 'production';
  const {
    sku,
    id,
    name,
    retail_price,
    currencyId,
    currencyFormat,
    color,
    size,
    grape_variety,
    vintage,
    images,
    winery,
    country
  } = product;

  const formattedPrice = formatPrice(retail_price, currencyId);
  let imageurl;
  let wineryName = "";

  if (images.length > 0) {
    if (isProduction) {
        imageurl = "'" + process.env.REACT_APP_BASEURL_PRODUCTION! + process.env.REACT_APP_WINE_IMAGE_PATH! + images[0].name + "'";
    } else {
      imageurl = "'" + process.env.REACT_APP_BASEURL_DEVELOPMENT! + process.env.REACT_APP_WINE_IMAGE_PATH! + images[0].name + "'";
    }

  } else {
    imageurl = process.env.REACT_APP_WINE_DUMMY_IMAGE
  }


  if (winery.name) {
    wineryName = winery.name;
  }

  const handleAddProduct = () => {
    addProduct({ ...product, quantity: 1 });
    openCart();
  };

  const handleShowProduct = () => {
    navigate("/detail/" + product.sku);
  };

  const handleAddProductWhenEnter = (event: KeyboardEvent) => {
    if (event.key === 'Enter' || event.code === 'Space') {
      addProduct({ ...product, quantity: 1 });
      openCart();
    }
  };

  return (
    <S.Container sku={sku} tabIndex={1}>
      <S.InnerContainer onClick={handleShowProduct}>

        <S.ImageContainer>
          <S.Image alt={name} style={{background: `url(${imageurl})`,   backgroundPosition: 'center',  backgroundSize: 'cover',  backgroundRepeat: 'no-repeat'}}/>
        </S.ImageContainer>

        <S.BuyContainer>
          <S.Price>
            <S.Val>
              <b>{currencyFormat}</b>
              <b>{formattedPrice.substring(0, formattedPrice.length - 3)}</b>
              <span>{formattedPrice.substring(formattedPrice.length - 3)}</span>
            </S.Val>
          </S.Price>
          <S.BuyButton onClick={handleAddProduct} tabIndex={-1}>
            ADD TO BAG
          </S.BuyButton>
        </S.BuyContainer>

        <S.DetailContainer>
          <S.Winery>{wineryName} {size}ml</S.Winery>
          <S.Title>{name} {vintage}</S.Title>
          <S.Country>{color} {country}</S.Country>
        </S.DetailContainer>
    </S.InnerContainer>
    </S.Container>
  );
};

export default Product;
