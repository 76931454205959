import axios from 'axios';
import { IGetWinesResponse } from 'models';

const isProduction = process.env.NODE_ENV === 'production';

export const getProducts = async () => {
  let response: IGetWinesResponse;

  if (isProduction) {
    response = await axios.get(
      process.env.REACT_APP_BASEURL_PRODUCTION + '/api/fetch-wines-summary'
    );
  } else {
    response = await axios.get(
      process.env.REACT_APP_BASEURL_DEVELOPMENT + '/api/fetch-wines-summary'
    );
  }

  const { wines } = response.data.data || [];

  return wines;
};
