import * as S from './style';

const Logo = () => {

  return (
    <S.SearchContainer>
      <S.SearchIcon />
    </S.SearchContainer>

  );
};

export default Logo;
