import styled from 'styled-components/macro';
import CB from 'commons/Checkbox';



export const Icon = styled.div`
  width: 50px;
  height: 50px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  background-image: url(${require('static/wine-icon.png')});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  background-size: 50%;
`;

export const Container = styled.div`
width: 5vw;
position: relative;
padding-left:5vw;

`;

export const DropDownContainer = styled.div`
width: 250px;
height: 150px;
position: relative;
`;


export const CheckboxLarge = styled(CB)`
  display: block;
  margin-bottom: 10px;

  /* Customize the label (the container) */
  label {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 80%;
    height: 25px;
    margin-bottom: 8px;
    margin-right: 8px;
    text-align: center;
    font: normal normal normal 16px/58px  'Montserrat';

    /* On mouse-over, add a border with the primary color */
    &:hover input ~ .checkmark {
      border: 1px solid ${({ theme }) => theme.colors.primary};
    }

    input:focus-visible ~ .checkmark {
      box-sizing: border-box;
      line-height: 30px;
      border: 3px solid ${({ theme }) => theme.colors.secondary};
    }

    /* When the checkbox is checked, add the primary color to background */
    & input:checked ~ .checkmark {
      background-color: ${({ theme }) => theme.colors.primary};
      color: #ececec;
    }

    /* Show the checkmark when checked */
    & input:checked ~ .checkmark:after {
      display: block;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      width: 80%;
      height: 30px;
      font-size: 0.8em;
      border-radius: 5px;
      box-sizing: border-box;
      line-height: 35px;
      text-align: center;
      padding-left:5px;

      color: ${({ theme }) => theme.colors.primary};
      text-align:left;
      background-color: transparent;

      border: 1px solid transparent;
    }
  }
`;
