import styled from 'styled-components/macro';

export const Container = styled.div``;


export const MainTitle = styled.div`
font: normal normal normal 54px/58px 'Crimson Text';
  padding-left:100px;
`;

export const ThreeColumnGridInner = styled.main`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1366px;
  margin: 0px auto auto;

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const ThreeColumnGridNarrow = styled.main`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1366px;
  margin: 0px auto auto;

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    grid-template-columns: 1fr 2fr 1fr;
  }
`;

export const ThreeColumnGrid = styled.main`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1366px;
  margin: 50px auto auto;

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const FourColumnGrid = styled.main`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1366px;
  margin: 0px auto auto;

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const MiddleSide = styled.div`
  display: grid;
  justify-content: center;
  padding: 15px;
  box-sizing: border-box;

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    align-content: baseline;
  }
`;
export const CartSide = styled.div`
  display: grid;
  justify-content: right;
  padding: 15px;
  box-sizing: border-box;

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    align-content: baseline;
  }
`;

export const CartSideLeft = styled.div`
  display: grid;
  justify-content: left;
  padding: 15px;
  box-sizing: border-box;

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    align-content: baseline;
  }
`;

export const Banner = styled.div`
    width: 1366px;
    margin: 0px auto auto;
    height:270px;
  position: relative;
  background-image: url(${require('static/banner.png')});
`;

export const TwoColumnGrid = styled.main`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1366px;
  margin: 50px auto auto;

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    grid-template-columns: 1fr 4fr;
  }
`;

export const Side = styled.div`
  display: grid;
  justify-content: center;
  padding: 15px;
  box-sizing: border-box;

  @media only screen and (min-width: ${({ theme: { breakpoints } }) =>
      breakpoints.tablet}) {
    align-content: baseline;
  }
`;

export const Main = styled.main``;

export const MainHeader = styled.main`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: end;
  padding: 0 15px;
  font: normal normal 15px/21px 'Montserrat';
  font-weight:700;
`;
