import styled from 'styled-components/macro';


export const LogoIcon = styled.div`
  width: 266px;
  height: 82px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  background-image: url(${require('static/logo.png')});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

`;

export const LogoContainer = styled.div`
width: 266px;
height: 82px;
position: relative;
margin: 0 auto auto;
`;
