import { useCallback } from 'react';

import { useProductsContext } from './ProductsContextProvider';
import { IWine } from 'models';
import { getProducts } from 'services/products';

const useProducts = () => {
  const {
    isFetching,
    setIsFetching,
    products,
    setProducts,
    filters,
    setFilters,
  } = useProductsContext();

  const fetchProducts = useCallback(() => {
    setIsFetching(true);
    getProducts().then((products: IWine[]) => {
      setIsFetching(false);
      setProducts(products);
    });
  }, [setIsFetching, setProducts]);

  const filterProducts = (filters: any) => {
    setIsFetching(true);

    getProducts().then((products: IWine[]) => {
      setIsFetching(false);
      let filteredProducts:IWine[];

      filteredProducts = [];

      products.forEach(p => {
        let doAdd = false;
        if (filters.price && filters.price > 0 && filters.price < 10000) {
          if (p.retail_price <= filters.price) {
              doAdd =true;
            }
        }


        if (filters.type && filters.type.length > 0) {
          filters.type.forEach((type:string) => {
            if (p.color === type) {
              doAdd =true;
            }
          });
        }
        if (filters.grape && filters.grape.length > 0) {
          filters.grape.forEach((grape:string) => {
            if (p.grape_variety === grape) {
              doAdd =true;
            }
          });
        }
        if (filters.country && filters.country.length > 0) {
          filters.country.forEach((country:string) => {
            if (p.country === country) {
              doAdd =true;
            }
          });
        }
        if (filters.region && filters.region.length > 0) {
          filters.region.forEach((region:string) => {
            if (p.region === region) {
              doAdd =true;
            }
          });
        }

        if (doAdd) {
          filteredProducts.push(p);
        }

      });


      if (filteredProducts.length === 0 && filters.region.length === 0 && filters.country.length === 0 && filters.grape.length === 0 && filters.type.length === 0 && filters.price === 10000) { filteredProducts = products };

      setFilters(filters);
      setProducts(filteredProducts);
    });
  };

  return {
    isFetching,
    fetchProducts,
    products,
    filterProducts,
    filters,
    setFilters
  };
};

export default useProducts;
