import { Link } from 'react-router-dom';
import formatPrice from 'utils/formatPrice';
import * as S from './style';

const Logo = () => {

  return (
    <S.LogoContainer>
      <Link to="/"><S.LogoIcon /></Link>
    </S.LogoContainer>

  );
};

export default Logo;
