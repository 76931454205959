import formatPrice from 'utils/formatPrice';
import * as S from './style';

const Fav = () => {

  return (
    <S.FavContainer>
      <S.FavIcon />
    </S.FavContainer>

  );
};

export default Fav;
