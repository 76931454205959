import * as S from './style';

const Profile = () => {

  return (
    <S.ProfileContainer>
      <S.ProfileIcon />
    </S.ProfileContainer>

  );
};

export default Profile;
